<template>
    <div id="home">
        <Bio />
    </div>
</template>

<script>
import Bio from '@/components/Bio'

export default {
    components: {
        Bio
    }
}
</script>